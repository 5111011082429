import { Button, Grid, Modal, Pagination, Progress, Space } from 'antd';
import Search from 'antd/lib/input/Search';
import Axios from 'axios';
import * as queryString from 'query-string';
import { ParsedQuery } from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUrlSearchParams } from 'use-url-search-params';

import { NavigationNode, Part } from '~/api/API';
import { deleteBookmarkPartItem } from '~/api/AuthorizedDeletes';
import { getCSV, getPartFilters, getPartItems } from '~/api/AuthorizedGets';
import { putAddBookmarkPartItem } from '~/api/AuthorizedPuts';
import { GET_LINK_ADD_PARTS_ITEM } from '~/constants/paths';
import { AppState } from '~/store/reducers';
import { KeyValue, PartItem } from '~/types';
import { getParts, selectNavigation, selectPartByPartName } from '~/utils/parts';
import { isStateLoading } from '~/utils/state';

import PartsBreadcrumb from './components/PartsBreadcrumb';
import PartsFilter from './components/PartsFilter/PartsFilter';
import PartsItemCard from './components/PartsItemCard';
import PartsNavigation from './components/PartsNavigation';
import styles from './PartsView.module.scss';
import { customStringify } from '~/utils/filter';
import { isEmpty } from 'lodash';
import {
  convertToNestedFormat,
  filterData,
  getFiltersDetails,
  isMultipleFilterExist,
  sortMultiple,
} from '~/utils/double-filter';
import CSVReader from './csvUpload3';
import CSVDownloader from './downloadCsv';

const { useBreakpoint } = Grid;

type PropsFromState = {
  state: AppState;
  parts: Part[];
  isLoading: boolean;
  isAuthenticated: boolean;
  permissions: string[];
};

type PartsViewProps = PropsFromState;

// Navigation Hooks
const getSelectedNavigation = (
  navigation: NavigationNode | null,
  parsedSearch: queryString.ParsedQuery,
): NavigationNode[] | null => {
  if (!navigation || !navigation.nodes) {
    return null;
  }

  if (!parsedSearch || !Object.keys(parsedSearch).length) {
    return navigation.nodes;
  }

  const selected = navigation.nodes.find(
    (e) => parsedSearch[e.name] !== null && parsedSearch[e.name] !== undefined,
  );

  if (selected) {
    return getSelectedNavigation(selected, { ...parsedSearch, [selected.name]: null });
  }

  return navigation.nodes;
};

const PartsView = (props: PartsViewProps) => {
  const { lg, xs } = useBreakpoint();
  const { state, parts, isLoading, isAuthenticated, permissions } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const partName = params.partName || '';

  const [navigation, setNavigation] = useState<NavigationNode[]>([]);

  const [partItems, setPartItems] = useState<PartItem[]>([]);
  const [searchQuery, setSearchQuery] = useState({} as KeyValue);
  const [searchText, setSearchText] = useState('');
  const [parsedSearch, setParsedSearch] = useState<ParsedQuery>(
    queryString.parse(location.search, { sort: false }),
  );

  // Navigation State
  const [selectedNavigations, setSelectedNavigations] = useState<NavigationNode[] | null>(null);
  const [partFiltersData, setPartFiltersData] = useState<KeyValue<KeyValue<number>> | undefined>(
    undefined,
  );
  const [navigationHistories, setNavigationHistories] = useState([parsedSearch]);
  const [navigationStep, setNavigationStep] = useState(0);

  // Hooks for pagination logic
  const initial = { page: 1 };
  const types = { page: Number };
  const [queries, setQueries] = useUrlSearchParams(initial, types);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalParts, setTotalParts] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageLimit, setPageLimit] = useState(pageSize);
  const [pageOffset, setPageOffset] = useState(0);
  const [percent, setPercent] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const part = useMemo(() => {
    if (!parts) return null;
    return selectPartByPartName(parts, partName || '');
  }, [parts, partName]);

  const rootNavigation = useMemo(() => {
    if (!parts) return null;
    return selectNavigation(navigation, partName || '');
  }, [parts, navigation, partName]);

  useEffect(() => {
    if (state?.data?.tenantConfig?.data?.navigation) {
      setNavigation(state.data.tenantConfig.data.navigation);
    }
  }, [state?.data?.tenantConfig?.data?.navigation]);

  useEffect(() => {
    setParsedSearch(queryString.parse(location.search, { sort: false }));
  }, [location.search]);

  // Hooks to defined offset and currentPage on the pagination
  useEffect(() => {
    const page = Number(queries.page);

    if (isNaN(page)) {
      setQueries({ page: 1 });
      setCurrentPage(1);
      setPageOffset(0);
    }

    const max = page * pageSize;
    const offset = max - pageSize;

    setCurrentPage(page);
    setPageOffset(offset);
  }, [queries.page, pageSize, setQueries]);

  const handlePageChange = (value: number) => {
    const max = value * pageSize;
    const offset = max - pageSize;

    setQueries({ page: value });
    setCurrentPage(value);
    setPageOffset(offset);
  };

  const onShowSizeChange = (current: number, newPageSize: number) => {
    const max = current * newPageSize;
    const offset = max - newPageSize;

    setQueries({ page: current });
    setCurrentPage(current);
    setPageSize(newPageSize);
    setPageLimit(newPageSize);
    setPageOffset(offset);
  };

  const resetPage = () => {
    setQueries({ page: 1 });
    setCurrentPage(1);
    setPageOffset(0);
  };

  const doSearch = useCallback(async () => {
    const source = Axios.CancelToken.source();

    try {
      if (!part) {
        return;
      }

      const isPagination = true;

      const result = await getPartItems(
        part.name,
        parsedSearch,
        isPagination,
        pageLimit,
        pageOffset,
        source,
      );

      if (result !== undefined) {
        const nestedFilters = convertToNestedFormat(parsedSearch, ['t']);

        if (
          part.filters &&
          result.count > 0 &&
          isMultipleFilterExist(parsedSearch) &&
          !isEmpty(nestedFilters)
        ) {
          if (result.count !== pageSize) {
            return onShowSizeChange(1, result.count);
          }

          const sortedNestedFilters = sortMultiple(nestedFilters, part.filters);
          const filtersDetails = getFiltersDetails(part, sortedNestedFilters);
          const filteredData = filterData(result.data, filtersDetails);

          setTotalParts(filteredData.length);
          setPartItems(PartItem.fromArray(filteredData));
        } else {
          setTotalParts(result.count);
          setPartItems(PartItem.fromArray(result.data));
        }
      }
    } catch (error) {
      setPartItems([]);
    }

    return () => {
      // Cancel Request when unmounting
      source.cancel();

      // Scroll up when it's render new page
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part, parsedSearch, pageLimit, pageOffset]);

  // Search Hooks
  useEffect(() => {
    doSearch();
  }, [doSearch]);

  const onSearch = (searchString: string) => {
    resetPage();
    setSearchText(searchString);
    const search = queryString.stringify({
      ...searchQuery,
      q: searchString,
    });
    navigate({
      pathname: location.pathname,
      search,
    });
  };

  const getFilters = useCallback(() => {
    const source = Axios.CancelToken.source();
    parsedSearch.t = partName;

    getPartFilters(parsedSearch, source).then((filters: any) => {
      if (filters !== undefined) {
        setPartFiltersData(filters);
      }
    });

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  }, [partName, parsedSearch]);

  useEffect(getFilters, [getFilters]);

  const handleSelectedNavigations = useCallback(() => {
    setSelectedNavigations(getSelectedNavigation(rootNavigation, parsedSearch));
  }, [rootNavigation, parsedSearch]);

  useEffect(handleSelectedNavigations, [handleSelectedNavigations]);

  const handleFiltering = (filters: KeyValue, preserveOldFilters = false) => {
    resetPage();

    const searchQuery = customStringify(
      !preserveOldFilters ? filters : { ...parsedSearch, ...filters },
    );

    setNavigationHistories([...navigationHistories, filters]);
    setNavigationStep(navigationHistories.length);

    navigate({
      pathname: location.pathname,
      search: searchQuery,
    });
  };

  const handleNavigationBack = () => {
    navigationHistories.pop();

    if (navigationHistories.length === 0) {
      navigate({
        pathname: location.pathname,
      });

      return;
    }

    const step = navigationStep - 1;
    setNavigationStep(step);
    navigate({
      pathname: location.pathname,
      search: queryString.stringify(navigationHistories[step]),
    });
  };

  useEffect(() => {
    const query: any = queryString.parse(location.search || '', { sort: false }) || {};
    setSearchQuery(query);
    setSearchText(query.q || '');
  }, [location.search]);

  const resetFilters = () => {
    navigate({ pathname: location.pathname });
  };

  const handleBookmark = async (item: PartItem) => {
    if (!item.is_favorite) {
      await putAddBookmarkPartItem(String(item.id));
    } else {
      await deleteBookmarkPartItem(item.id);
    }
    item.is_favorite = !item.is_favorite;
    setPartItems((v) => [...v]);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.layout}>
      <Modal
        title=""
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        centered
        style={
          {
            // top: '50%',
            // transform: 'translateY(-50%)',
          }
        }
        styles={{
          content: {
            background: 'transparent',
            boxShadow: 'none',
          },
          mask: {
            background: '#d8d8d873',
          },
        }}
        closeIcon={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Progress
            type="circle"
            trailColor="#d9d9d9"
            size={40}
            strokeColor={'#00d3b2'}
            percent={percent}
          />
          <p>Downloading...</p>
        </div>
        {/* )} */}
      </Modal>

      <PartsBreadcrumb partName={partName} parts={parts} search={location.search} />

      {lg && (
        <PartsFilter
          filters={searchQuery}
          handleFiltering={handleFiltering}
          resetFilters={resetFilters}
          selectedPartName={partName}
          noDefaultFilterField
        />
      )}
      <div className={styles.header}>
        <h1>{part ? `${part.display_name} (${totalParts})` : 'Loading ...'}</h1>
        <div className={styles.headerRight}>
          {!lg && (
            <PartsFilter
              filters={searchQuery}
              handleFiltering={handleFiltering}
              resetFilters={resetFilters}
              selectedPartName={partName}
              noDefaultFilterField
            />
          )}
          <Search
            className={styles.search}
            placeholder="Search Parts"
            value={searchText}
            onChange={(e) => onSearch(e.target.value)}
            onSearch={onSearch}
          />
        </div>
      </div>
      {part && !isMultipleFilterExist(parsedSearch) && (
        <PartsNavigation
          handleFiltering={handleFiltering}
          handleNavigationBack={handleNavigationBack}
          isLoading={isLoading}
          part={part}
          partFiltersData={partFiltersData}
          selectedNavigations={selectedNavigations}
          showNavigationBack={navigationHistories.length > 0}
        />
      )}

      {isAuthenticated && permissions?.includes('WriteAllPart') && (
        <Space>
          <Link to={GET_LINK_ADD_PARTS_ITEM(String(part?.name))}>
            <Button className={styles.addItemButton} type="primary">
              Add New {part?.display_name}
            </Button>
          </Link>

          <CSVDownloader
            partName={partName}
            location={location}
            getCSV={getCSV}
            setPercent={setPercent}
            showModal={showModal}
            handleCancel={handleCancel}
          />

          <CSVReader />
        </Space>
      )}

      <PartsItemCard
        handleBookmark={handleBookmark}
        isLoading={isLoading}
        partItems={partItems}
        parts={parts}
      />

      {!isLoading && (
        <Pagination
          className={styles.pagination}
          current={currentPage}
          pageSize={pageSize}
          simple={xs}
          total={Number(totalParts)}
          showSizeChanger
          onChange={handlePageChange}
          onShowSizeChange={onShowSizeChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const parts = getParts(state) || [];

  return {
    parts,
    state,
    isLoading: isStateLoading(state.action, 'tenantConfig', 'partItems', 'partFilters'),
    isAuthenticated: state.data.auth.authenticated,
    permissions: state.data.whoami.data?.permissions,
  };
};

export default connect(mapStateToProps)(PartsView);
