import { Button, Input, Space, Table, Tag, Radio, RadioChangeEvent } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { formatDistanceToNow, format } from 'date-fns';
import styles from './Users.module.scss';

import { getUsers } from '~/api/AuthorizedGets';
import { AppState } from '~/store/reducers';
import { AuthState } from '~/store/reducers/data/Auth';
import { isStateLoading } from '~/utils/state';
import { useNavigate } from 'react-router-dom';
import { InviteUserModal } from './invite-users';

type propsFromState = {
  auth: AuthState;
  userData: any[];
  isLoading: boolean;
  permissions: string[] | undefined;
  tenantName: string;
  isTenant?: boolean;
};

type UsersProps = propsFromState;

const Users = ({ auth, userData, isLoading, permissions, isTenant, tenantName }: UsersProps) => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [defaultEmailDomain, setDefaultEmailDomain] = useState('');

  const getUserData = () => {
    if (auth.authenticated) {
      getUsers();
    }
  };

  useEffect(getUserData, [auth.authenticated]);

  useEffect(() => {
    if (userData && userData.length > 0) {
      const newData = userData.filter((data) => {
        return data.email.toLowerCase().includes(defaultEmailDomain);
      });

      setFilteredData(newData);
    }
  }, [defaultEmailDomain, userData]);

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any, dataIndex: string) => {
    clearFilters();
    setSearchText('');

    if (dataIndex === 'email') {
      setDefaultEmailDomain('');
    }
  };

  const searchInput = createRef<HTMLInputElement>();

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={() => searchInput}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        {dataIndex === 'email' && tenantName === 'str' && (
          <Radio.Group
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '0.5rem',
            }}
            onChange={(e: RadioChangeEvent) => {
              setDefaultEmailDomain(e.target.value);
            }}
            value={defaultEmailDomain}
          >
            <Radio value="str-subsea.com">str-subsea.com</Radio>
            <Radio value="anekonnect.io">anekonnect.io</Radio>
          </Radio.Group>
        )}
        <Space>
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          >
            Search
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),

    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',

    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.select();
          }
        }, 100);
      }
    },

    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          textToHighlight={text ? text.toString() : ''}
          autoEscape
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Name',
      className: styles.column,
      dataIndex: 'username',
      key: 'name',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Email',
      className: styles.column,
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Status',
      className: styles.column,
      dataIndex: 'disabled',
      key: 'status',
      filters: [
        {
          text: 'Active',
          value: false,
        },
        {
          text: 'Disabled',
          value: true,
        },
      ],
      onFilter: (value: any, record: any) => record.disabled === value,
      render: (disabled: boolean) =>
        disabled ? <Tag color="red">Disabled</Tag> : <Tag color="green">Active</Tag>,
    },
    {
      title: 'Roles',
      className: styles.column,
      dataIndex: 'roles',
      key: 'roles',
      filters: [
        {
          text: 'User',
          value: 'User',
        },
        {
          text: 'Part Manager',
          value: 'PartManager',
        },
        {
          text: 'Vendor',
          value: 'Vendor',
        },
        {
          text: 'Admin',
          value: 'Admin',
        },
        {
          text: 'Super Admin',
          value: 'SuperAdmin',
        },
      ],
      onFilter: (value: any, record: any) => record.roles?.includes(value),
      render: (roles: any) => roles?.map((role: string) => <Tag key={role}>{role}</Tag>),
    },
    {
      title: 'Last Active',
      className: styles.column,
      dataIndex: 'last_active',
      key: 'last_active',
      sorter: (a: any, b: any) => {
        const dateA = a.last_active ? new Date(a.last_active).getTime() : 0;
        const dateB = b.last_active ? new Date(b.last_active).getTime() : 0;
        return dateA - dateB;
      },
      render: (v: string | null | undefined) => {
        if (!v) return <span>—</span>; // Display a placeholder if last_active is blank

        const date = new Date(v);
        return (
          <Tooltip title={format(date, 'PPpp')}>
            <span>{formatDistanceToNow(date, { addSuffix: true })}</span>
          </Tooltip>
        );
      },
    },
  ];

  const homeColumns = [
    {
      title: 'Company',
      className: styles.column,
      dataIndex: 'companyname',
      key: 'company',
      ...getColumnSearchProps('companyname'),
    },
    {
      title: 'Payment Status',
      className: styles.column,
      dataIndex: 'payment_status',
      key: 'payment_status',
      filters: [
        {
          text: 'Trial',
          value: 'Trial',
        },
        {
          text: 'Paid',
          value: 'Paid',
        },
        {
          text: 'Abandoned',
          value: 'Abandoned',
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.payment_status === value;
      },
      sorter: (a: any, b: any) =>
        a.payment_status ? a.payment_status.localeCompare(b.payment_status) : 0,
    },
    {
      title: 'Trial Expiration Date',
      className: styles.column,
      dataIndex: 'trial_expiry',
      key: 'trial_expiry',
      sorter: (a: any, b: any) => {
        return a.trial_expiry ? a.trial_expiry.localeCompare(b.trial_expiry) : 0;
      },
    },
  ];

  const userListColumns = [
    {
      title: 'User List',
      className: styles.tableHeader,
      children: isTenant === false ? [...columns, ...homeColumns] : columns,
    },
  ];

  return (
    <React.Fragment>
      <InviteUserModal showModal={showInviteUser} onClose={() => setShowInviteUser(false)} />
      {permissions?.includes('CreateUser') && (
        <Button
          className={styles.inviteButton}
          size="large"
          type="primary"
          onClick={() => setShowInviteUser(true)}
        >
          Invite User
        </Button>
      )}

      <Table
        columns={userListColumns}
        dataSource={filteredData}
        loading={isLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`/users/${record.username}`);
            },
          };
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    auth: state.data.auth,
    userData: state.data.users?.data,
    isLoading: isStateLoading(state.action, 'users'),
    permissions: state.data.whoami.data?.permissions,
    isTenant: state.data.tenantConfig?.isTenant,
    tenantName: state.data.tenantConfig?.data?.name,
  };
};

export default connect(mapStateToProps)(Users);
