// Base API Request
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const ERROR = 'ERROR';

// User
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_PROFILE';
export const LOGIN_USER = 'LOGIN_USER';
export const OAUTH_USER = 'OAUTH_USER';
export const WHOAMI = 'WHOAMI';
export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_USER = 'GET_USER';
export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';
export const ADD_ADMIN_ACCESS = 'ADD_ADMIN_ACCESS';
export const REMOVE_ADMIN_ACCESS = 'REMOVE_ADMIN_ACCESS';
export const DISABLE_USER = 'DISABLE_USER';
export const ENABLE_USER = 'ENABLE_USER';
export const INVITE_USER = 'INVITE_USER';
export const SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const GET_PLANS = 'GET_PLANS';
export const GET_COUPON = 'GET_COUPON';
export const UPDATE_TEMPLATE_SETTINGS = 'UPDATE_TEMPLATE_SETTINGS';
export const UPDATE_TITLE_BLOCK_SCHEMA = 'UPDATE_TITLE_BLOCK_SCHEMA';
export const UPDATE_TRIAL_DATE = 'UPDATE_TRIAL_DATE';
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_ALL_VENDOR_USERS = 'GET_ALL_VENDOR_USERS';
export const UPDATE_USER_PAYMENT = 'UPDATE_USER_PAYMENT';
export const UPDATE_PARTS_INDEX = 'UPDATE_PARTS_INDEX';

// Connector
export const CREATE_CONNECTOR = 'CREATE_CONNECTOR';
export const UPDATE_CONNECTOR = 'UPDATE_CONNECTOR';

// Tenant
export const HOME_TENANT = 'home';
export const TENANT_CONFIG = 'TENANT_CONFIG';

// Parts
export const CREATE_PART_ITEM = 'CREATE_PART_ITEM';
export const EDIT_PART_ITEM = 'EDIT_PART_ITEM';
export const SEARCH_PART_ITEMS = 'SEARCH_PART_ITEMS';
export const READ_PART_FILTERS = 'READ_PART_FILTERS';
export const READ_PART_ITEMS = 'READ_PART_ITEMS';
export const READ_PART_ITEM = 'READ_PART_ITEM';
export const ADD_BOOKMARK_PART_ITEM = 'ADD_BOOKMARK_PART_ITEM';
export const REMOVE_BOOKMARK_PART_ITEM = 'REMOVE_BOOKMARK_PART_ITEM';
export const SHARE_PART = 'SHARE_PART';
export const DELETE_PART_ITEM = 'DELETE_PART_ITEM';
export const REQUEST_DOWNLOAD_SVG_FILES = 'REQUEST_DOWNLOAD_SVG_FILES';

// API Response
export const PART_SHARE_SUCCESS_RESPONSE = 'Part shared successfully';
export const REQUEST_DOWNLOAD_SVG_FILES_SUCCESS_RESPONSE =
  'Request download svg files successfully';

// Assemblies
export const GET_DATA_ASSEMBLIES = 'GET_DATA_ASSEMBLIES';
export const UPDATE_ASSEMBLY_UPLOAD = 'UPDATE_ASSEMBLY_UPLOAD';
export const GET_DATA_ASSEMBLIES_BY_ID = 'GET_DATA_ASSEMBLIES_BY_ID';
export const SAVE_DATA_ASSEMBLY = 'SAVE_DATA_ASSEMBLY';
export const UPDATE_ASSEMBLY_NO_REDIRECT = 'UPDATE_ASSEMBLY_NO_REDIRECT';
export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';
export const SHARE_ASSEMBLY = 'SHARE_ASSEMBLY';
export const REMOVE_ACCESS_ASSEMBLY = 'REMOVE_ACCESS_ASSEMBLY';
export const COPY_ASSEMBLY = 'COPY_ASSEMBLY';
export const AS_BUILT_ASSEMBLY = 'AS_BUILT_ASSEMBLY';
export const DELETE_ASSEMBLY = 'DELETE_ASSEMBLY';
export const DELETE_ASSEMBLY_ATTACHMENT = 'DELETE_ASSEMBLY_ATTACHMENT';
export const CREATE_DISCUSSION_TOPIC = 'CREATE_DISCUSSION_TOPIC';
export const DELETE_ASSEMBLY_DISCUSSION = 'DELETE_ASSEMBLY_DISCUSSION';

// Tags
export const GET_TAGS = 'GET_TAGS';
export const POST_NEW_TAG = 'POST_NEW_TAG';

// Notification
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

// SVG Exporter
export const POST_EXPORT_SVG = 'POST_EXPORT_SVG';

//CSV Download
export const DOWNLOLAD_CSV = 'DOWNLOLAD_CSV';

// QUOTES
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const GET_ALL_REQUEST_QUOTE = 'GET_ALL_REQUEST_QUOTE';
export const CREATE_QUOTE_RESPONSE = 'CREATE_QUOTE_RESPONSE';
export const GET_ALL_QUOTE_RESPONSE = 'GET_ALL_QUOTE_RESPONSE';
export const DELETE_QUOTE_RESPONSE = 'DELETE_QUOTE_RESPONSE';
export const UPDATE_QUOTE_RESPONSE = 'UPDATE_QUOTE_RESPONSE';
export const CREATE_QUOTE_ORDER = 'CREATE_QUOTE_ORDER';
export const GET_ALL_QUOTE_ORDERS = 'GET_ALL_QUOTE_ORDERS';

// Approvals
export const GET_REVIEWERS = 'GET_REVIEWERS';
export const UPDATE_REQUEST_REVIEW = 'UPDATE_REQUEST_REVIEW';
export const DELETE_CANCEL_REVIEW = 'DELETE_CANCEL_REVIEW';
export const GET_APPROVAL_HISTORY = 'GET_APPROVAL_HISTORY';
export const UPLOAD_PARTS_CSV = 'UPLOAD_PARTS_CSV';

// SSO
export const GET_ZENDESK_TOKEN = 'GET_ZENDESK_TOKEN';
