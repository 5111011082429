import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';

import { ComponentData } from '../../Types';

import { mx } from '~/constants/wizard';
import { ObjectShape } from '~/store/reducers/configs';
import useGetClickedPostion from '~/hooks/useGetClickedPostion';

type DrawProps = {
  data: ComponentData;
  graph: mxGraph;
  objects: ObjectShape[];
};

const Draw = ({ graph, data: bezierCurveData, objects }: DrawProps) => {
  const clickedPostion = useGetClickedPostion();

  const drawLine = useCallback(
    (parent: mxCell) => {
      const { id, index, position } = bezierCurveData;

      const dimensionLineId = `cable_ed_${id}_${index}_dimension_line`;

      if (graph.getModel().getCell(dimensionLineId)) {
        return;
      }

      const cell = new mx.mxCell(
        '3 mm +0/-0 mm',
        new mx.mxGeometry(0, 0, 320, 0),
        'endArrow=classic;startArrow=classic;html=1;rounded=0;movable=1;verticalAlign=bottom;fontSize=14;bendable=0;edgeStyle=none;',
      );
      cell.id = dimensionLineId;
      cell.geometry.setTerminalPoint(
        new mx.mxPoint(
          position.engineeringDrawing.x + clickedPostion.x - 100,
          position.engineeringDrawing.y + clickedPostion.y - 30 * 2,
        ),
        true,
      );
      cell.geometry.setTerminalPoint(
        new mx.mxPoint(
          position.engineeringDrawing.x + clickedPostion.x + 220,
          position.engineeringDrawing.y + clickedPostion.y - 30 * 2,
        ),
        false,
      );
      // cell.geometry.setTerminalPoint(
      //   new mx.mxPoint(clickedPostion.x - 100, clickedPostion.y - 30 * 2),
      //   true,
      // );
      // cell.geometry.setTerminalPoint(
      //   new mx.mxPoint(clickedPostion.x + 220, clickedPostion.y - 30 * 2),
      //   false,
      // ); this is previous data so we add default position to work with clicked position also on 10 feb 25
      cell.geometry.relative = true;
      cell.edge = true;

      graph.addEdge(cell, parent);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bezierCurveData, graph],
  );

  const drawBezier = useCallback(
    (parent: mxCell) => {
      objects.forEach((object) => {
        const configs = object.configs;
        const { index, id, defaultAlias, default_color, default_thickness, position } =
          bezierCurveData;

        const bezierCurveId = `cable_ed_${id}_${index}_bezier_curve`;

        if (graph.getModel().getCell(bezierCurveId)) {
          return;
        }

        const doc = mx.mxUtils.createXmlDocument();
        const node = doc.createElement('component');
        node.setAttribute('label', defaultAlias);
        node.setAttribute('configs', JSON.stringify(configs));

        const cell = new mx.mxCell(
          node,
          new mx.mxGeometry(0, 30, 320, 0),
          `endArrow=none;html=1;strokeWidth=${default_thickness || 20};strokeColor=${
            default_color || 'black'
          };curved=1;movable=1;fontSize=15;edgeStyle=elbowEdgeStyle;`,
        );
        cell.id = bezierCurveId;
        // cell.geometry.setTerminalPoint(
        //   new mx.mxPoint(clickedPostion.x - 100, clickedPostion.y - 15),
        //   true,
        // );
        // cell.geometry.setTerminalPoint(
        //   new mx.mxPoint(clickedPostion.x + 220, clickedPostion.y - 15),
        //   false,
        // ); change this code and add existing posiotn with clicked position
        cell.geometry.setTerminalPoint(
          new mx.mxPoint(
            position.engineeringDrawing.x + clickedPostion.x - 100,
            position.engineeringDrawing.y + clickedPostion.y - 15,
          ),
          true,
        );
        cell.geometry.setTerminalPoint(
          new mx.mxPoint(
            position.engineeringDrawing.x + clickedPostion.x + 220,
            position.engineeringDrawing.y + clickedPostion.y - 15,
          ),
          false,
        );
        cell.geometry.relative = true;
        cell.edge = true;

        graph.addEdge(cell, parent);
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objects, bezierCurveData, graph],
  );

  const addBezier = useCallback(() => {
    const { mxClient, mxUtils } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      if (graph) {
        const parent = graph.getDefaultParent();
        graph.getModel().beginUpdate();

        try {
          if (bezierCurveData.id) {
            drawBezier(parent);
            drawLine(parent);
          }
        } finally {
          graph.getModel().endUpdate();
        }
      }
    }
  }, [graph, drawBezier, bezierCurveData, drawLine]);

  useEffect(addBezier, [addBezier]);

  return <React.Fragment />;
};

export default Draw;
