import { mxCell, mxGraph, StyleMap } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';

import { Ref } from '../../Types';

import Constant from './Constant';
import { ConnectorShareProps } from './Types';

import { mx } from '~/constants/wizard';
import { ObjectShape } from '~/store/reducers/configs';
import useGetClickedPostion from '~/hooks/useGetClickedPostion';

type DrawProps = {
  graph: mxGraph;
  defaultStylesRef: Ref<StyleMap | undefined>;
  objects: ObjectShape[];
  splitValue: any;
  parentid: any;
  labelname: any;
  clog: any;
} & ConnectorShareProps;

const { mxClient, mxUtils, mxPoint } = mx;

const Draw = ({
  graph,
  defaultStylesRef,
  data,
  objects,
  splitValue,
  parentid,
  labelname,
  clog,
}: // clog,
DrawProps) => {
  const {
    defaultAlias,
    lengthOfAllItems,
    totalHeightOfContainer,
    hasShell,
    options: { schematics: schematicsSize },
    type,
    index,
    id,
    contact_details: contactDetails,
  } = data;

  const clickedPosition = useGetClickedPostion();

  const drawConnector = useCallback(
    (parent: mxCell) => {
      objects.forEach((object) => {
        const configs = object.configs;
        // const rootId = `${type}_schematics_${id}_${index}_draw`;
        let rootId;
        if (!parentid) {
          rootId = `${type}_schematics_${id}_${index}_draw`;
        } else {
          rootId = parentid;
        }
        const labelStyle = `fontSize=10;verticalLabelPosition=middle;labelBackgroundColor=white;labelPadding=1;indicatorSpacing=20px`;

        let remainingItems = lengthOfAllItems;
        let pinIndex = 0; // To keep track of pin numbering across multiple sets
        let pinSetIndex = 0;

        while (remainingItems > 0) {
          // console.log(splitValue, data, 'draw data', objects);

          const itemsInThisSet = Math.min(splitValue || remainingItems, remainingItems);
          // const itemsInThisSet = Math.min(20, remainingItems);
          // console.log(
          //   itemsInThisSet,
          //   'this sheet item',
          //   parentid,
          //   labelname,
          //   'splitvalue->',
          //   splitValue,
          //   'clog->',
          //   clog,
          // );
          // const parentId = `${rootId}_container_parent_${pinSetIndex + 1}`;
          let parentId;
          if (splitValue === 0) {
            parentId = `${rootId}_container_parent`;
          } else {
            if (splitValue) {
              // console.log('inside split value');

              parentId = `${rootId}_container_parent_${pinSetIndex + 1}`;
            } else {
              parentId = `${rootId}_container_parent`;
            }
          }

          // let parentId;
          // if(!parentid){
          //   console.log("inside props parent id");
          //   parentId = `${rootId}_container_parent_${pinSetIndex + 1}`;

          // }else{
          //    parentId = parentid;
          // }

          const startValue = pinIndex + 1;
          // console.log(rootId, 'rootid', parentId);

          if (graph.getModel().getCell(parentId)) {
            pinIndex += itemsInThisSet;
            remainingItems -= itemsInThisSet;
            pinSetIndex += 1;
            continue;
          }
          // console.log(defaultAlias, 'label draw');

          const doc = mx.mxUtils.createXmlDocument();
          const node = doc.createElement('component');
          if (labelname) {
            node.setAttribute('label', labelname);
          } else {
            node.setAttribute('label', defaultAlias);
          }
          node.setAttribute('configs', JSON.stringify(configs));

          /** Calculate height based on the number of pins in this set */
          const test = (totalHeightOfContainer - 20) / lengthOfAllItems;
          const connectorHeight = itemsInThisSet * test; // Total height needed for this set of pins
          // const adjustedHeight = Math.max(connectorHeight, 600); // Ensure height is at least 600
          // const adjustedHeight = (connectorHeight- (connectorHeight*0.22)); // Ensure height is at least 600
          let adjustedHeight;
          if (hasShell) {
            adjustedHeight = connectorHeight + 20; // Ensure height is at least 600
          } else {
            adjustedHeight = connectorHeight;
          }

          // console.log(itemsInThisSet, 'set height', connectorHeight, adjustedHeight, test);
          const positionx =
            clog === 'instrument'
              ? data?.position?.schematics.x
              : clickedPosition.x + pinSetIndex * 100;
          const positiony =
            clog === 'instrument' ? data?.position?.schematics.y : clickedPosition.y + 140;

          /** CREATE CONNECTOR CONTAINER */
          const connectorContainer = graph.insertVertex(
            parent,
            parentId,
            node,
            positionx,
            positiony,
            // clickedPosition.x + pinSetIndex * 100, // Adjust X position for the new pin > update this at 10feb and change this and put above due to instrument connector not place correctly
            // clickedPosition.y + 140, //this define position of shema in Y Axis
            40,
            adjustedHeight,
            `shadow=1;fillColor=#fff;strokeColor=#000000;strokeWidth=1;`,
          );
          connectorContainer.setConnectable(false);
          connectorContainer.geometry.height = adjustedHeight;
          connectorContainer.geometry.offset = new mxPoint(0, -adjustedHeight / 2 - 27);
          /** END OF CONNECTOR CONTAINER */

          /** CREATE CONNECTOR PIN */
          const pinValue =
            contactDetails?.length > 0
              ? contactDetails[pinIndex]?.contact_representation
              : `${startValue}`;
          const connectorPinRoot = graph.insertVertex(
            connectorContainer,
            `${rootId}_${Constant.connectorPinId}_${startValue}`,
            pinValue,
            0,
            0,
            schematicsSize.width,
            schematicsSize.height,
            `fillColor=#f0f8ff;rounded=0;rotatable=0;strokeColor=#000000;strokeWidth=1;`,
          );

          connectorPinRoot.geometry.relative = true;
          connectorPinRoot.geometry.offset = new mxPoint(0, hasShell ? 10 : 0);
          connectorPinRoot.setConnectable(false);
          connectorPinRoot
            .getParent()
            .setStyle('resizable=0;strokeColor=#000000;strokeWidth=1;rounded=1');

          // LOOP CONNECTOR PIN
          for (let i = 1; i < itemsInThisSet; i++) {
            const connectorPointNext = connectorPinRoot.clone();
            // console.log(connectorPointNext, 'get id from connetore');

            const contactDetail = contactDetails?.[pinIndex + i];
            if (contactDetail) {
              connectorPointNext.setValue(contactDetail.contact_representation);
            } else {
              connectorPointNext.setValue(`${startValue + i}`);
            }

            connectorPointNext.setId(`${rootId}_${Constant.connectorPinId}_${startValue + i}`);
            connectorPointNext.geometry.offset = new mxPoint(
              0,
              i * (Constant.connectorHeight - 10) + (hasShell ? 10 : 0),
            );
            connectorContainer.insert(connectorPointNext, i);
          }
          /** END OF CREATE CONNECTOR PIN */

          /** CREATE CONNECTOR PORT */
          // PORT LEFT
          const portLeftStyle = `shape=line;align=right;verticalAlign=middle;routingCenterX=${Constant.endTerminalPointStart};spacingRight=9;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};${labelStyle};rotatable=0;`;
          const portLeftRoot = graph.insertVertex(
            connectorContainer,
            `${rootId}_${Constant.portLeftId}_${startValue}`,
            contactDetails && contactDetails[pinIndex]?.annotation_position !== 'right'
              ? contactDetails[pinIndex]?.contact_prefilled_annotation || null
              : null,
            0,
            0,
            Constant.portWidth,
            Constant.portHeight,
            // 50,
            portLeftStyle,
          );
          portLeftRoot.geometry.relative = true;
          portLeftRoot.geometry.offset = new mxPoint(
            -connectorPinRoot.geometry.width / 2 + Constant.portWidth,
            connectorPinRoot.geometry.height / 2 - (hasShell ? 0 : 10) + 2,
          );
          portLeftRoot.setConnectable(true);

          // PORT RIGHT
          const portRightStyle = `shape=line;align=left;verticalAlign=middle;routingCenterX=${Constant.endTerminalPointEnd};spacingLeft=9;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};${labelStyle};rotatable=0;`;
          const portRightRoot = graph.insertVertex(
            connectorContainer,
            `${rootId}_${Constant.portRightId}_${startValue}`,
            contactDetails && contactDetails[pinIndex]?.annotation_position === 'right'
              ? contactDetails[pinIndex]?.contact_prefilled_annotation || null
              : null,
            0,
            0,
            Constant.portWidth,
            Constant.portHeight,
            portRightStyle,
          );
          portRightRoot.geometry.relative = true;
          portRightRoot.geometry.offset = new mxPoint(
            connectorPinRoot.geometry.width,
            connectorPinRoot.geometry.height / 2 - (hasShell ? 0 : 10) + 2,
          );
          portRightRoot.setConnectable(true);

          // PORT TOP & PORT BOTTOM
          // Only create port top and bottom when hasShell true
          if (hasShell) {
            const portTopRoot = graph.insertVertex(
              connectorContainer,
              `${rootId}_${Constant.portTopId}_${startValue}`,
              null,
              0,
              0,
              0.1,
              10,
              `align=center;rounded=0;fontColor=#000;strokeColor=#000;dashed=0;routingCenterY=${Constant.endTerminalPointStart};rotatable=0;`,
            );
            portTopRoot.geometry.relative = true;
            portTopRoot.geometry.offset = new mxPoint(
              connectorPinRoot.geometry.width / 2,
              -Math.abs(connectorPinRoot.geometry.offset.y - 10 + 10),
            );
            portTopRoot.setAttribute(Constant.portTopId, 1);
            portTopRoot.setConnectable(true);

            const portBottomRoot = graph.insertVertex(
              connectorContainer,
              `${rootId}_${Constant.portBottomId}_${startValue}`,
              null,
              0,
              0,
              0.1,
              10,
              `align=center;rounded=0;fontColor=#000;strokeColor=#000;dashed=0;routingCenterY=${Constant.endTerminalPointEnd};rotatable=0;`,
            );
            portBottomRoot.geometry.relative = true;
            portBottomRoot.geometry.offset = new mxPoint(
              connectorPinRoot.geometry.width / 2,
              adjustedHeight,
            );
            portBottomRoot.setConnectable(true);
          }

          // LOOP CONNECTOR PORT
          for (let i = 1; i < itemsInThisSet; i++) {
            const startConnectorPosition = i * connectorPinRoot.geometry.height + 2;
            const connectorPositionCenter =
              connectorPinRoot.geometry.height / 2 - (hasShell ? 0 : 10);

            const portLeftNext = graph.insertVertex(
              connectorContainer,
              `${rootId}_${Constant.portLeftId}_${startValue + i}`,
              contactDetails && contactDetails[pinIndex + i]?.annotation_position !== 'right'
                ? contactDetails[pinIndex + i]?.contact_prefilled_annotation || null
                : null,
              0,
              0,
              Constant.portWidth,
              Constant.portHeight,
              portLeftStyle,
            );
            portLeftNext.geometry.relative = true;
            portLeftNext.geometry.offset = new mxPoint(
              -connectorPinRoot.geometry.width / 2 + Constant.portWidth,
              startConnectorPosition + connectorPositionCenter,
            );
            portLeftNext.setConnectable(true);

            const portRightNext = graph.insertVertex(
              connectorContainer,
              `${rootId}_${Constant.portRightId}_${startValue + i}`,
              contactDetails && contactDetails[pinIndex + i]?.annotation_position === 'right'
                ? contactDetails[pinIndex + i]?.contact_prefilled_annotation || null
                : null,
              0,
              0,
              Constant.portWidth,
              Constant.portHeight,
              portRightStyle,
            );
            portRightNext.geometry.relative = true;
            portRightNext.geometry.offset = new mxPoint(
              connectorPinRoot.geometry.width,
              startConnectorPosition + connectorPositionCenter,
            );
            portRightNext.setConnectable(true);
          }

          pinIndex += itemsInThisSet;
          remainingItems -= itemsInThisSet;
          pinSetIndex += 1;
        }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      objects,
      parentid,
      lengthOfAllItems,
      type,
      id,
      index,
      splitValue,
      graph,
      labelname,
      totalHeightOfContainer,
      hasShell,
      contactDetails,
      schematicsSize.width,
      schematicsSize.height,
      defaultAlias,
    ],
  );

  const setDefaultVertexStyle = useCallback(() => {
    defaultStylesRef.current = graph.getStylesheet().getDefaultVertexStyle();
  }, [graph, defaultStylesRef]);

  useEffect(setDefaultVertexStyle, [setDefaultVertexStyle]);

  const addConnector = useCallback(() => {
    const isHaveItems = lengthOfAllItems > 0;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      const parent = graph.getDefaultParent();
      // console.log(parent, 'parent');

      graph.getModel().beginUpdate();

      try {
        if (isHaveItems) {
          drawConnector(parent);
        }
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }, [lengthOfAllItems, graph, drawConnector]);

  useEffect(addConnector, [addConnector]);

  return <React.Fragment />;
};

export default Draw;
