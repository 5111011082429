import { mxCell, mxGraph, StyleMap } from '@anekonnect/mxgraph';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { ComponentData } from '../../Types';
import DrawConnector from '../Connector/Draw';
import DrawCable from '../Cable/Draw';
import DrawPenetrator from '../Penetrator/Draw';
import { replaceStyles } from '../../Helper';

import { ObjectShape } from '~/store/reducers/configs';
import { getSearchPartItems } from '~/api/AuthorizedGets';
import { PartItem } from '~/types';
import { wizardSetIsLoading } from '~/store/actions/wizard/Action';
import { mx } from '~/constants/wizard';
import { getTotalHeightOfContainer } from '../Cable/Helper';

type PropsFromDispatch = {
  wizardSetIsLoading: typeof wizardSetIsLoading;
};

type DrawProps = {
  graph: mxGraph;
  data: ComponentData;
  objects: ObjectShape[];
  splitValue: number;
  labelname: string;
  parentid: any;
} & PropsFromDispatch;

type InstrumentData = { [key: string]: ComponentData[] };
type InstrumentTotalData = { [key: string]: number };

const Draw = ({
  parentid,
  labelname,
  splitValue,
  graph,
  data,
  objects,
  wizardSetIsLoading,
}: DrawProps) => {
  const {
    key: instrumentKey,
    defaultAlias,
    molded_cable_whip,
    cable_whip_details,
    connector_connections,
    penetrator_connections,
    type,
    index,
    id,
  } = data;

  const [instrumentData, setInstrumentData] = useState<InstrumentData>({});
  const [totalData, setTotalData] = useState<InstrumentTotalData>({});
  const defaultStylesRef = useRef<StyleMap>();
  const objectInstrument = objects.find((v) => v.type === 'draw_instrument');

  const findComponent = useCallback(
    async (instrumentKey: string, type: string, name: string, label = '', contactDetails = []) => {
      wizardSetIsLoading(true);

      const filter = { t: type };
      const result: PartItem[] = await getSearchPartItems(name, filter);

      if (result !== undefined && result.length > 0) {
        const component = result.find((v) => v.name === name);

        if (component) {
          const componentData: ComponentData = JSON.parse(JSON.stringify(component));
          const lengthOfAllItems = +component.contacts || 0;
          const reduceHeight = 10;
          const margin = 20;

          componentData.defaultAlias = label || component.name;
          componentData.lengthOfAllItems = lengthOfAllItems;
          componentData.totalHeightOfContainer =
            (40 - reduceHeight) * lengthOfAllItems + (component['has_shell'] ? margin : 0);
          componentData.hasShell = component['has_shell'] || false;
          componentData.wizardType = component.type;
          componentData.options = {
            engineeringDrawing: {
              width: 40,
              height: 40,
              position: { x: 0, y: 0 },
              angle: 0,
              mirror: false,
            },
            schematics: {
              width: 40,
              height: 40 - reduceHeight,
              position: { x: 200, y: 200 },
              angle: 0,
              mirror: false,
            },
          };
          componentData.position = {
            schematics: { x: 200, y: 200 },
            engineeringDrawing: { x: 0, y: 0 },
          };
          componentData.type = `instrument-child_${componentData.type}_${instrumentKey}`;

          if (type === 'cable') {
            componentData.totalHeightOfContainer = getTotalHeightOfContainer(componentData);
          }

          if (contactDetails.length > 0) {
            componentData.contact_details = contactDetails;
          }

          setInstrumentData((prev: InstrumentData) => {
            const prevData = prev[instrumentKey] || [];
            const positionY =
              prevData.length > 0
                ? prevData[prevData.length - 1].position.schematics.y +
                  prevData[prevData.length - 1].totalHeightOfContainer +
                  60
                : 200;

            componentData.index = prevData.length;
            componentData.position.schematics.y = positionY;

            if (type === 'penetrator') {
              componentData.position.schematics.x -= 30;
            }

            return {
              ...prev,
              [instrumentKey]: [...prevData, componentData],
            };
          });
        }

        wizardSetIsLoading(false);
      } else {
        wizardSetIsLoading(false);
      }
    },
    [wizardSetIsLoading],
  );

  const drawInstrument = useCallback(async () => {
    let total = 0;

    if (molded_cable_whip && cable_whip_details) {
      await findComponent(instrumentKey, 'cable', cable_whip_details);
      total += 1;
    }

    if (connector_connections && connector_connections.length > 0) {
      for (let i = 0; i < connector_connections.length; i++) {
        const connector = connector_connections[i];

        if (connector.connector_name) {
          await findComponent(
            instrumentKey,
            'connector',
            connector.connector_name,
            connector.connector_designator,
            connector.contact_details,
          );
          total += 1;
        }
      }
    }

    if (penetrator_connections && penetrator_connections.length > 0) {
      for (let i = 0; i < penetrator_connections.length; i++) {
        const penetrator = penetrator_connections[i];

        if (penetrator.penetrator_name) {
          await findComponent(
            instrumentKey,
            'penetrator',
            penetrator.penetrator_name,
            penetrator.penetrator_designator,
            penetrator.contact_details,
          );
          total += 1;
        }
      }
    }

    setTotalData((prev: InstrumentTotalData) => ({ ...prev, [instrumentKey]: total }));
  }, [
    instrumentKey,
    connector_connections,
    cable_whip_details,
    molded_cable_whip,
    penetrator_connections,
    findComponent,
  ]);

  useEffect(() => {
    drawInstrument();
  }, [drawInstrument]);

  useEffect(() => {
    for (const key in instrumentData) {
      const cells: mxCell[] = Object.values(graph.model.cells);

      const groupIdExist = cells.find((v) => v.id.includes(`instrument_schematics_${key}`));

      if (!groupIdExist) {
        // eslint-disable-next-line no-prototype-builtins
        if (instrumentData.hasOwnProperty(key)) {
          const element = instrumentData[key];
          const total = totalData[key];

          if (element.length > 0 && element.length === total) {
            const instrumentCells: mxCell[] = [];

            for (let i = 0; i < element.length; i++) {
              const v = element[i];
              const cellId = `${v.type}_schematics_${v.id}_${v.index}_draw_container_parent`;
              const cell = graph.model.getCell(cellId);

              if (cell) {
                instrumentCells.push(cell);
              }

              const cellIdShielded = `${v.type}_schematics_${v.id}_${v.index}_draw_container_shielded_parent`;
              const cellShielded = graph.model.getCell(cellIdShielded);

              if (cellShielded) {
                instrumentCells.push(cellShielded);
              }
            }

            graph.alignCells('center', instrumentCells);

            const group = graph.groupCells(null, 25, instrumentCells);
            group.setId(`${type}_schematics_${id}_${index}_draw_container_parent`);

            if (objectInstrument?.configs) {
              const doc = mx.mxUtils.createXmlDocument();
              const node = doc.createElement('component');
              node.setAttribute('label', defaultAlias);
              node.setAttribute('configs', JSON.stringify(objectInstrument?.configs));
              group.setValue(node);
            } else {
              group.setValue(defaultAlias);
            }

            const newStyle = replaceStyles(group, [
              {
                name: 'group',
                value: '1',
              },
              {
                name: 'strokeWidth',
                value: '2',
              },
              {
                name: 'strokeColor',
                value: '#000000',
              },
              {
                name: 'fontSize',
                value: '14',
              },
            ]);

            group.setStyle(newStyle);
            group.geometry.y = 200;
            group.geometry.offset = new mx.mxPoint(0, -group.geometry.height / 2 - 27);

            const gap = group.children[0].geometry.height / 2;

            let totalHeight = 0;

            for (let i = 0; i < group.children.length; i++) {
              const v = group.children[i];

              v.geometry.y -= gap;

              if (v.id.includes('cable')) {
                v.geometry.x -= 10;
              }

              totalHeight += v.geometry.height;
            }

            totalHeight += 50 * group.children.length * 2 - 40;

            if (group.children.length === 1) {
              totalHeight += 60;
            }

            group.geometry.height = totalHeight;
            group.geometry.offset = new mx.mxPoint(0, -totalHeight / 2 - 27);

            graph.refresh();
            wizardSetIsLoading(false);
          }
        }
      }
    }
  }, [
    graph,
    type,
    id,
    index,
    instrumentKey,
    instrumentData,
    totalData,
    defaultAlias,
    objectInstrument,
    wizardSetIsLoading,
  ]);
  // console.log('call step 2 instrument ');

  const handleLabelPositionOnResize = useCallback(() => {
    graph.addListener(mx.mxEvent.CELLS_RESIZED, (_sender: any, evt: any) => {
      const { cells } = evt.properties;
      const { mxGeometry } = mx;

      cells.forEach((cell: any) => {
        if (cell.id.includes('instrument_schematics')) {
          const { geometry } = cell;

          if (geometry instanceof mxGeometry) {
            const { height } = geometry;
            const { x } = geometry.offset;

            geometry.offset = new mx.mxPoint(x, -height / 2 - 27);
          }
        }
      });
    });
  }, [graph]);

  useEffect(handleLabelPositionOnResize, [handleLabelPositionOnResize]);

  return (
    <>
      {Object.keys(instrumentData).map((v) => {
        const data = instrumentData[v];

        return data.map((v) => {
          return (
            <>
              <DrawConnector
                clog={'instrument'}
                data={v}
                defaultStylesRef={defaultStylesRef}
                labelname={labelname}
                graph={graph}
                objects={objects}
                splitValue={splitValue}
                parentid={parentid}
              />
              <DrawCable data={v} graph={graph} objects={objects} />
              <DrawPenetrator
                data={v}
                defaultStylesRef={defaultStylesRef}
                graph={graph}
                objects={objects}
              />
            </>
          );
        });
      })}
    </>
  );
};

const mapDispatchToProps = {
  wizardSetIsLoading,
};

export default connect(null, mapDispatchToProps)(Draw);
